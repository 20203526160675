import * as React from 'react';
import {Divider} from 'antd';
import {Helmet} from 'react-helmet';

// @ts-ignore
import logo from '../static/sidelines.jpg';
// @ts-ignore
import Icon from '../static/svgs/cribl-logo.svg';
import '../styles/terms.less';

const TermsPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Terms</title>
        <link rel='canonical' href='https://portal.cribl.cloud/terms' />
      </Helmet>
      <div
        key={'terms'}
        className={'header'}
        style={{
          backgroundImage: `url(${logo})`
        }}
      >
        <Icon
          style={{
            height: '30px',
            top: '4px',
            position: 'relative'
          }}
        />
        <div>Terms Of Service</div>
      </div>
      <div style={{minHeight: '35px', backgroundColor: '#0cc'}} />
      <Divider />
      <div style={{marginLeft: '20%', marginRight: '20%'}}>
        <h1>Standard License Agreement for Cribl’s LogStream Software</h1>
        <div>
          <h2>1. Agreement to use LogStream.</h2>
          <p>
            This agreement allows you to use the LogStream software and describes the contractual relationship between
            you and Cribl, Inc. arising from your use of LogStream. The laws of California govern this agreement and all
            disputes shall be resolved in state or federal courts in California. The United Nations Convention on
            Contracts for the International Sale of Goods does not apply.
          </p>
          <p>
            You can use LogStream only if you read, understand, and accept this agreement and have the authority to
            accept this agreement. If you are accepting this agreement on behalf of an organization, you represent that
            you have the authority to do so.{' '}
          </p>
          <p>
            You accept this agreement by clicking “I agree” or similar terms when first downloading, installing, or
            using LogStream. If you accept this agreement, you can use LogStream as provided by this agreement until the
            agreement ends. Cribl may change this agreement and the LogStream software and features it offers at any
            time after posting a notice to its website. Your continued use of LogStream constitutes continuing agreement
            to these terms.
          </p>
        </div>

        <h2> 2. Cribl, LogStream, and Packs.</h2>
        <div>
          <p>
            Cribl owns LogStream and retains all rights related to all versions of LogStream, including all intellectual
            property rights related to Cribl and LogStream. LogStream is available at
            <a href={'http://www.cribl.io'}>http://www.cribl.io</a>. Cribl may provide LogStream to other customers and
            may provide software updates to LogStream from time to time at no cost to you. Certain additional updates
            with new features must be purchased from Cribl.
          </p>
          <p>
            Packs are software or configuration files that provide features and settings for LogStream. Packs may be
            governed by separate licenses. Packs may be offered by Cribl, Cribl’s partners, and others.
          </p>
        </div>

        <h2>3. Your use of LogStream and Packs.</h2>
        <div>
          <p>
            You can use LogStream Free or LogStream One by accepting this agreement. You can use LogStream Standard or
            LogStream Enterprise by accepting this agreement and purchasing the appropriate license and usage terms from
            Cribl or an authorized partner. You will be charged the fees for LogStream Standard and LogStream Enterprise
            as described in your ordering instrument based on your license and usage terms.{' '}
          </p>
          <p>
            You can use LogStream Cloud by accepting this agreement. You will be charged fees for LogStream Cloud based
            on your usage and the fee schedule located at
            <a href={'https://cribl.io/cribl-logstream-pricing/'}>https://cribl.io/cribl-logstream-pricing/</a>, which
            is incorporated by reference into this agreement. Cribl may change its fee schedule after providing notice
            on its website. You will be charged the fees that are in effect on the day of your use.
          </p>
          <p>
            You can use a Pack by accepting the license terms for that Pack and, if applicable, purchasing the
            appropriate license and usage terms from the licensing party for that Pack. Packs offered by Cribl are
            subject to this agreement and any other terms provided by the given Pack. You will be charged fees for any
            Packs that have fees as described in the license terms for those Packs.
          </p>
          <p>
            Cribl will provide support services for LogStream as described at{' '}
            <a href={'https://cribl.io/support/'}>https://cribl.io/support/.</a>
          </p>
          <p>
            You can authorize other persons or organizations to use LogStream on your behalf, but you cannot process
            data using LogStream for anyone else or let anyone else use your access to LogStream to process data using
            LogStream for anyone else other than you. You are responsible for all use related to your access to
            LogStream.
          </p>
          <p>
            You must follow all applicable laws, rules, regulations, contracts, and other legal requirements. You must
            comply with all applicable import and export controls and obtain all necessary licenses and approvals
            necessary to download, deploy, or use LogStream. You cannot download, deploy, or use LogStream in any way
            that violates any legal requirement or right of a third party. You cannot modify LogStream, create
            derivative works from LogStream, or reverse engineer any part of LogStream unless permitted by applicable
            law.
          </p>
        </div>

        <h2> 4. Data and confidentiality.</h2>

        <div>
          <p>
            You own all your data, including data that you input into LogStream or process with LogStream. You are
            responsible for protecting your data from loss, alteration, and disclosure. You are responsible for
            protecting access to your data and for protecting any encryption keys or security credentials needed to
            access data encrypted by LogStream.{' '}
          </p>
          <p>
            Cribl receives and uses data as provided by Cribl’s Privacy Policy, which is available at
            <a href={'https://www.cribl.io/privacy-policy'}>https://www.cribl.io/privacy-policy</a> and which is
            incorporated by reference into this agreement. Your data does not include Cribl’s operational data as
            described in Cribl’s Privacy Policy. You cannot send personal data to Cribl unless you are allowed to do so
            by applicable law and only then to the extent allowed by applicable law and Cribl’s Privacy Policy.
          </p>
          <p>
            You and Cribl must take reasonable steps to protect each other’s confidential information, including by
            following legal requirements, limiting access to the other party’s confidential information, and ensuring
            each other’s employees and other agents do not violate this agreement. Confidential information includes
            without limitation information designated verbally or in writing as confidential, trade secrets, and any
            other proprietary information.
          </p>
          <p>
            You and Cribl can only use and disclose each other’s confidential information for purposes related to this
            agreement and as required by law. You and Cribl may not otherwise disclose to any party not subject to this
            agreement any confidential information of the other party without the consent of the other party or as
            required by law.
          </p>
        </div>

        <h2>5. No warranties and limited liability.</h2>

        <div>
          <p>
            You and Cribl are entitled to all legal or equitable remedies that will satisfy each of our rights and
            obligations under this agreement or your obligation to pay for LogStream or any additional LogStream
            features, Packs, or other features you have purchased from Cribl.
          </p>

          <p>
            If a court finds that either party breached this contract and losses were suffered as a result of that
            breach, the breaching party will compensate the non-breaching party for such losses to the extent allowed by
            this agreement.
          </p>

          <p>
            The maximum cumulative liability under any theory of liability related to this agreement is limited to the
            total amount of fees you paid to Cribl in the 12 months preceding the first event giving rise to the
            liability unless the liability relates to your violation of Cribl’s intellectual property rights OR A
            VIOLATION OF THE CONFIDENTIALITY REQUIREMENTS IN SECTION 4.
          </p>

          <p>
            Cribl provides LogStream “as is” and you assume the entire risk of using LogStream. Cribl is not responsible
            for your use of LogStream or any consequences to you or anyone else that may occur related to your use of
            LogStream or the reliance on data processed by LogStream.
          </p>

          <p>
            Cribl makes no express or implied warranties or representations related to LogStream, including without
            limitation warranties or representations of merchantability, fitness for a particular purpose or
            requirement, title, quality, accuracy, reliability, and non-infringement.
          </p>

          <p>
            You and Cribl are not liable to each other related to your use of LogStream for any indirect, consequential,
            punitive, incidental, SPECIAL, or exemplary damages.
          </p>

          <p>
            You and Cribl are not liable to each other related to your use of LogStream for any loss of revenue, loss of
            profits, loss of data, equipment REPLACEMENT COSTS, data replacement costs, or operating costs.
          </p>

          <p>
            You and Cribl are not liable to each other if it becomes impossible to comply with this agreement, but each
            of us must use reasonable efforts to try to mitigate such issues.
          </p>

          <p>
            This agreement does not create any employment, partnership, joint venture, or other agency relationship
            between you and Cribl. This agreement only acts to benefit you and Cribl; it does not act to benefit any
            third party.
          </p>
        </div>

        <h2>6. Ending the agreement.</h2>

        <div>
          You and Cribl can end the agreement at any time for any reason by providing written notice to the other party.
          If the agreement ends, you must pay, within thirty days from the date the agreement ended, any unpaid balance
          owed to Cribl related to your use of LogStream less the balance attributable to the unused remainder of the
          contract term or unused paid usage, as applicable. Cribl will refund prior payments attributable to the unused
          remainder of the contract term or unused paid usage. All obligations and rights in Section 4 and Section 5
          survive the end of this agreement.
        </div>

        <h2>7. Notices.</h2>

        <div>
          You must send all notices related to this agreement in writing to notice@cribl.io. Cribl must send all notices
          related to this agreement in writing to the email address you used to accept this agreement. You and Cribl
          agree that notices sent pursuant to this agreement are legally sufficient for all purposes.
        </div>

        <p>--</p>
        <p>Version 1.05. Updated 2021-05-18.</p>
      </div>
      <div style={{minHeight: '35px', backgroundColor: '#0cc'}} />
      <Divider />
    </>
  );
};

export default TermsPage;
